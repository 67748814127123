<template>
  <div class="liveMeet" style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
    <div class="liveMeet-top">
      <div class="liveMeet-top-input" style="width: 30%">
        <div>状态:</div>
        <a-radio-group
            style="margin-left: 5px"
            v-model="radioValue"
            @change="onChange">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="1">
            已发布
          </a-radio-button>
          <a-radio-button value="0">
            未发布
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="liveMeet-top-input">
        <div>标题:</div>
        <a-input
            v-model="titleData"
            class="input"
            placeholder="请输入标题" />
      </div>
      <div class="liveMeet-top-input">
        <div>所属平台:</div>
        <a-select
            @change="domainCodeChange"
            class="input"
            v-model="domainCode"
            placeholder="请选择所属平台">
          <a-select-option
              v-for="select_S in yun_subordinate_select"
              :value="select_S.domain_code">
            {{select_S.name}}
          </a-select-option>
        </a-select>
      </div>
      <div class="liveMeet-top-input">
        <div>年份:</div>
        <a-date-picker
            v-model="year"
            mode="year"
            v-decorator="['year']"
            placeholder="请输入年份"
            format="YYYY"
            class="input"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
      </div>
      <div class="liveMeet-top-input">
        <a-button
            @click="seachBtn"
            type="primary">查询</a-button>
        <a-button
            @click="resetBtn"
            style="margin-left: 10px">重置</a-button>
      </div>
    </div>
    <div class="liveMeet-table">
      <div class="liveMeet-table-div">
        <div
            @click="addBtn"
            class="liveMeet-table-div-div-one">
          <div class="liveMeet-table-div-div-one-font">
            +新增
          </div>
        </div>
        <div
            v-for="(list,index) in liveList"
            :key="index"
            class="liveMeet-table-div-div">
          <div class="box">
            <img :src="list.detailCoverUrl" alt="">
<!--            <div class="upDown">-->
<!--              <div-->
<!--                  style="width: 95%;-->
<!--                  margin: auto;-->
<!--                  display: flex;-->
<!--                  padding-top: 25%;-->
<!--                  justify-content: space-between">-->
<!--                <div class="font" @click="upBtn(list)">-->
<!--                  <a-icon  type="step-backward" />-->
<!--                </div>-->
<!--                <div class="font" @click="DownBtn(list)">-->
<!--                  <a-icon  type="step-forward" />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="content">
            <div class="content-title">{{list.title}}</div>
            <div class="content-time">
              <div>{{list.displayTime}}</div>
              <div style="margin-right: 15px">
                <a-switch
                    v-model="list.isShow === 1?true:false"
                    checked-children="APP已发布"
                    un-checked-children="APP未发布"
                    @click="switchChange(list.id,list.isShow === 1? 0 : 1)"
                    default-checked />
              </div>
            </div>
            <div style="height: 10px"></div>
            <div class="content-btn">
              <div >
                <a @click="editBtn(list)" class="btn-one">编辑</a>
              </div><span style="color: #e8e8e8">|</span>
              <a @click="setBtn(list.id,list)" class="btn-one">设置</a><span style="color: #e8e8e8">|</span>
              <div @click="promotionBtn(list)" class="btn-one">开场图</div><span style="color: #e8e8e8">|</span>
              <a @click="dataBtn(list.id)" class="btn-one">数据</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--          分页-->
    <div style="width: 100%;height: 50px;background-color: #f0f2f5;">
      <div style="float: right;margin-right: 20px;margin-top: 15px">
        <a-pagination
            show-quick-jumper
            :current="page_no"
            @change="listChange"
            :total="listCount" />
      </div>
    </div>
<!--------------------------------------------对话框------------------------------------>
<!--   新增-->
    <a-drawer
        title="新增"
        :width="680"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="onClose"
    >
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="form.title" placeholder="请输入标题"/>
        </a-form-model-item>
        <a-form-model-item ref="name" label="显示会议时间" prop="displayTime">
          <a-input v-model="form.displayTime" placeholder="请输入显示会议时间"/>
        </a-form-model-item>
        <a-form-model-item label="所属平台" prop="domainCode">
          <a-select
              v-model="form.domainCode"
              placeholder="请选择所属平台"
              @change="Subordinate_handleChange">
            <a-select-option
                v-for="select_S in yun_subordinate"
                :value="select_S.domain_code">
              {{select_S.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="直播显示时间" prop="sortTime">
          <div style="display: flex;width: 100%;justify-content: space-between">
            <div>
              <a-date-picker
                  :allowClear="false"
                  placeholder="请选择直播显示时间"
                  @change="live_begin_time_change_add"
                  format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              />
            </div>
            <div style="margin-left: 10px">
              <div class="aleart">
                <a-icon
                    style="background-color: #1890ff;
                  height: 14px;width: 14px;
                  border-radius: 14px;
                  color: #e8e8e8"
                    type="exclamation-circle" />用于排序和倒计时
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="coverUrl" label="列表封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="uplistCover"
                  ref="Thumbnail" />
            </div>
            <div
                class="upload_image">
              <img
                  v-if="form.coverUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="form.coverUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸1920*520，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item  label="详情封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upDetailsCover"
                  ref="detailCoverUrl" />
            </div>
            <div
                class="upload_deyails">
              <img
                  v-if="form.detailCoverUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="form.detailCoverUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>
        </a-form-model-item>
        <a-form-model-item  label="详情海报">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upPosterCover"
                  ref="descImageUrl" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="form.descImageUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="form.descImageUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过3M</div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetForm">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit">
          确认
        </a-button>
      </div>
    </a-drawer>
<!--   编辑 -->
    <a-drawer
        title="编辑"
        :width="680"
        :visible="editVisible"
        :body-style="{ paddingBottom: '80px' }"
        @close="onEditClose"
    >
      <a-form-model
          ref="ruleEditForm"
          :model="editForm"
          :rules="rulesEdit"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="editForm.title" placeholder="请输入标题"/>
        </a-form-model-item>
        <a-form-model-item label="显示会议时间" prop="displayTime">
          <a-input v-model="editForm.displayTime" placeholder="请输入显示会议时间"/>
        </a-form-model-item>
        <a-form-model-item label="所属平台" prop="domainCode">
          <a-select
              disabled
              placeholder="请选择所属平台"
              v-model="editForm.domainCode"
              @change="Subordinate_handleChange">
            <a-select-option
                v-for="select_r in yun_subordinate"
                :value="select_r.domain_code">
              {{select_r.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="直播显示时间" prop="sortTime">
          <div style="display: flex;width: 100%;justify-content: space-between">
            <div>
              <a-date-picker
                  :allowClear="false"
                  v-model="editForm.sortTime"
                  placeholder="请选择直播显示时间"
                  @change="live_begin_time_change_rest"
                  format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              />
            </div>
            <div style="margin-left: 10px">
              <div class="aleart">
                <a-icon
                    style="background-color: #1890ff;
                  height: 14px;width: 14px;
                  border-radius: 14px;
                  color: #e8e8e8"
                    type="exclamation-circle" />用于排序和倒计时
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item  label="列表封面图" prop="coverUrl">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upEditlistCover"
                  ref="editThumbnail" />
            </div>
            <div
                class="upload_image">
              <img
                  v-if="editForm.coverUrl"
                  alt=""
                  style="width: 100%;height:100%;"
                  :src="editForm.coverUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸1920*520，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="详情封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upEditDetailsCover"
                  ref="editDeyails" />
            </div>
            <div
                class="upload_deyails">
              <img
                  v-if="editForm.detailCoverUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="editForm.detailCoverUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>
        </a-form-model-item>
        <a-form-model-item label="详情海报">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="upEditPosterCover"
                  ref="editPoster" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="editForm.descImageUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="editForm.descImageUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过3M</div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetEditForm">
          取消
        </a-button>
        <a-button type="primary" @click="onEditSubmit">
          确认
        </a-button>
      </div>
    </a-drawer>
    <!--   推广-->
    <a-drawer
        title="开场图"
        :width="600"
        :visible="promotion_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{this.promotion_visible = false;
          this.promotion_form.beginImageDetail = [];
          this.promotion_form.beginImageTime = null;
          // this.promotion_form.isBeginImage = null;
        }"
    >
      <a-form-model
          ref="promotion_ruleForm"
          :model="promotion_form"
          :rules="promotion_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item  label="开场图">
          <a-switch
              v-model="promotion_form.isBeginImage === 1?true:false"
              checked-children="开"
              un-checked-children="关"
              @change="promotion_Switch_btn(promotion_form.isBeginImage === 0?1:0)"
              default-checked />
        </a-form-model-item>
        <a-form-model-item prop="beginImageDetail" label="选择图片">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="promotion_upPosterCover"
                  ref="promotion_descImageUrl" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="promotion_form.beginImageDetail"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="promotion_form.beginImageDetail"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="时长" prop="beginImageTime">
          <a-input v-model.number="promotion_form.beginImageTime" placeholder="建议不超过5秒"/>
        </a-form-model-item>
<!--        <a-form-model-item label="APP 首页广告">-->
<!--          <a-switch-->
<!--              v-model="promotion_form.isBannerShow === 1?true:false"-->
<!--              checked-children="开"-->
<!--              un-checked-children="关"-->
<!--              @change="promotion_Switch_btn_openTwo(promotion_form.isBannerShow === 0?1:0)"-->
<!--              default-checked />-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="APP个人中心">-->
<!--          <a-switch-->
<!--              :defaultChecked="promotion_form.openThree === 1?true:false"-->
<!--              @change="promotion_Switch_btn_openThree(promotion_form.openThree === 0?1:0)"-->
<!--              checked-children="开"-->
<!--              un-checked-children="关"-->
<!--              default-checked />-->
<!--        </a-form-model-item>-->
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="()=>{
          this.promotion_visible = false;
          this.promotion_form.beginImageDetail = [];
          this.promotion_form.beginImageTime = null;
          this.promotion_form.isBeginImage = null;
          // this.promotion_form.isBannerShow = null;
        }">
          取消
        </a-button>
        <a-button type="primary" @click="promotion_Submit">
          确认
        </a-button>
      </div>
    </a-drawer>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import {update} from "../../utils/update";
import {difference} from '../../utils/timeDifference'
import {getListContent, getLiveList, LiveList_upOrDown, postLiveList, putLiveList} from "../../service/liveMeet_api";

export default {
  name: "LiveMeet",
  data(){
    return{
      yearShowOne:false,
      year:'',
      domainCode:'HYF',
      isopen: false,
      spinning:false,
      editTime:[],
      listId:'',
      page_no:1,
      page_size:10,
      radioValue:'',
      imgUrl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1113%2F032120114622%2F200321114622-4-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652409503&t=82d54dc8933a1d4957146dddf466d4f3',
      listCount:null,
      visible:false,
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      other: '',
      form: {
        title: '',
        displayTime:'',
        // dateRange:[],
        //所属平台
        domainCode:'HYF',
        //直播显示时间
        sortTime:'',
        coverUrl:'',
        detailCoverUrl:'',
        descImageUrl:''
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        displayTime: [{ required: true, message: '请输入会议时间', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        domainCode: [{ required: true, message: '请选择所属平台', trigger: 'blur' }],
        sortTime: [{ required: true, message: '请选择直播显示时间', trigger: 'blur' }],
      },
      rulesEdit: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        displayTime: [{ required: true, message: '请输入会议时间', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        domainCode: [{ required: true, message: '请选择所属平台', trigger: 'blur' }],
        sortTime: [{ required: true, message: '请选择直播显示时间', trigger: 'blur' }],
      },
      editForm: {
        title: '',
        displayTime:'',
        coverUrl:'',
        detailCoverUrl:'',
        descImageUrl:'',
        //所属平台
        domainCode:'HYF',
        //直播显示时间
        sortTime:'',
      },
      editVisible:false,
      liveList:[],
      titleData:'',
      timeList:[],
      promotion_visible:false,
      promotion_form:{
        isBeginImage:0,
        beginImageDetail:'',
        beginImageTime:null,
        // isBannerShow:0,
        // openThree:0,
      },
      promotion_rules: {
        beginImageDetail: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        beginImageTime: [{ required: true, message: '请输入时长', trigger: 'blur' }],
      },
      cardId:'',
      isBeginImageVal:null,
      yun_subordinate_select:[
        // {
        //   id:1,
        //   name:'全部',
        //   domain_code:'',
        // },
        {
          id:1,
          name:'汇医坊',
          domain_code:'HYF',
        },
        // {
        //   id:3,
        //   name:'云ICU',
        //   domain_code:'yunicu',
        // }, {
        //   id:4,
        //   name:'IE- Learning',
        //   domain_code:'ie-learning',
        // },{
        //   id:5,
        //   name:'APnet',
        //   domain_code:'aphouse',
        // },
      ],
      yun_subordinate:[
        {
          id:1,
          name:'汇医坊',
          domain_code:'HYF',
        },
      ]
    }
  },
  created() {
      this.$store.dispatch('setManageHeaderTitle',"直播会议管理")

  },
  mounted() {
    this.getLiveList()
  },
  methods:{
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      this.page_no = 1
      if(this.year){
        this.year = moment(this.year).format('YYYY')
        this.year = this.year+'-01-01 00:00:00'
      }
      this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
    },
      moment,
    //获取直播会议列表
    async getLiveList(page_no,page_size,title,isShow,sortTime,domainCode) {
      this.spinning = true
      const response = await getLiveList(page_no,page_size,title,isShow,sortTime,domainCode)
      if(response.code === 0){
        this.liveList = response.data.rows
        this.listCount = response.data.count
      }else {
        this.$message.warning("失败，请联系管理员"+response.message)
      }
      this.spinning = false
    },
    onChange(){
      this.page_no = 1
      this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
    },
    //分页
    listChange(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
    },
    addBtn(){
      this.visible = true
    },
    showDrawer() {
      this.visible = true;
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const response = await postLiveList(this.form)
          if(response.code === 0){
            this.$message.success("添加成功")
            this.visible = false
            //刷新列表
            await this.getLiveList()
          //  删除
            this.form = {}
          }else {
            this.$message.warning("失败请联系管理员"+response.message)
          }
        } else {
          this.$message.warning("表单错误！")
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    async onEditSubmit() {
      this.$refs.ruleEditForm.validate(async valid => {
        if (valid) {
          const response = await putLiveList(this.listId, this.editForm)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            //刷新列表
            await this.getLiveList(this.page_no, this.page_size, this.titleData, this.radioValue, this.year, this.domainCode)
            this.editVisible = false
          } else {
            this.$message.warning("失败请联系管理员" + response.message)
          }
          this.editTime = []
        } else {
          this.$message.warning("表单有空，请先填写")
          return false;
        }
      });
    },
    onEditClose(){
      this.editVisible = false
      this.editTime = []
      this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
    },
    resetEditForm() {
      this.editVisible = false;
      this.editTime = []
    },
    onClose(){
      this.visible = false
    },
    // 上传列表封面图
    async uplistCover() {
      let inputDOM = this.$refs.Thumbnail.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.form.coverUrl = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async upDetailsCover() {
      let inputDOM = this.$refs.detailCoverUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.form.detailCoverUrl = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    async upPosterCover() {
      let inputDOM = this.$refs.descImageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 3145728) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.form.descImageUrl = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于3M的图片！")
      }
    },
    async promotion_upPosterCover() {
      let inputDOM = this.$refs.promotion_descImageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 1045504) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.promotion_form.beginImageDetail = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    // 修改上传列表封面图
    async upEditlistCover() {
      let inputDOM = this.$refs.editThumbnail.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.editForm.coverUrl = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async upEditDetailsCover() {
      let inputDOM = this.$refs.editDeyails.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.editForm.detailCoverUrl = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    async upEditPosterCover() {
      let inputDOM = this.$refs.editPoster.files[0];
      //判断图片大小
      if (inputDOM.size < 3145728) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.editForm.descImageUrl = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于3M的图片！")
      }
    },
    editBtn(row){
      //判断当前时间是不是大于要修改的时间
      // if(row.sortTime !== undefined && row.sortTime !== null && row.sortTime !== ''){
      //   let date1 = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        // if(difference(date1,row.sortTime) <= 10){
          this.editVisible = true
          this.listId = row.id
          let form = this.editForm
          form.title = row.title
          form.displayTime = row.displayTime
          form.coverUrl = row.coverUrl
          form.detailCoverUrl = row.detailCoverUrl
          form.descImageUrl = row.descImageUrl
          form.domainCode = row.domainCode
          form.sortTime = row.sortTime
        // }
      // }
    },
    async switchChange(id, isShow) {
      const data = {
        isShow:isShow
      }
      const response = await putLiveList(id, data)
      if (response.code === 0) {
        this.$message.success("修改成功！")
        this.editVisible = false
      //  刷新列表
        await this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
      } else {
        this.$message.warning("失败请联系管理员" + response.message)
      }
    },
    seachBtn(){
      if(this.year){
        this.year = moment(this.year).format('YYYY')
        this.year = this.year+'-01-01 00:00:00'
      }
      this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
    },
    resetBtn(){
      this.titleData = ''
      this.radioValue = ''
      this.year = ''
      this.page_no = 1
      this.domainCode = undefined
      this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
    },
    domainCodeChange(){
      if(this.year){
        this.year = moment(this.year).format('YYYY')
        this.year = this.year+'-01-01 00:00:00'
      }
      this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
    },
    setBtn(id,row){
      this.$store.commit('liveImg',row)
      //路由跳转
      this.$router.push({path:'/setLiveMeet',query:{live_metting_id:id}})
    },
    dataBtn(id){
      //路由跳转
      this.$router.push({path:'/liveInformation',query:{live_metting_id:id}})
    },
    promotionBtn(list){
      // this.isBeginImageVal =
      this.cardId = list.id
      this.promotion_visible = true
      this.getLiveOneList(list.id)
    },
    //获取单个会议
    async getLiveOneList(id) {
      const response = await getListContent(id)
      if (response.code === 0) {
        let form = this.promotion_form
        this.promotion_form.isBeginImage = response.data.isBeginImage
        // this.promotion_form.isBannerShow = response.data.isBannerShow
        if(response.data.beginImageDetail !== null
            && response.data.beginImageDetail !== ''
            &&response.data.beginImageDetail !== undefined){
          this.promotion_form.beginImageDetail = response.data.beginImageDetail[0]
        }
        this.promotion_form.beginImageTime = response.data.beginImageTime

      } else {
        this.$message.warning("失败请联系管理员" + response.message)
      }
    },
    promotion_Submit() {
      this.$refs.promotion_ruleForm.validate(async valid => {
        if (valid) {
          let data = {
            "beginImageDetail": this.promotion_form.beginImageDetail.split(','),
            "beginImageTime": this.promotion_form.beginImageTime,
            "isBeginImage": this.promotion_form.isBeginImage,
            // "isBannerShow":this.promotion_form.isBannerShow
          }
          const response = await putLiveList(this.cardId, data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            this.promotion_visible = false
            this.promotion_form.beginImageDetail = [];
            this.promotion_form.beginImageTime = null;
            this.promotion_form.isBeginImage = null;
            // this.promotion_form.isBannerShow = null;
          } else {
            this.$message.warning("失败请联系管理员" + response.message)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async promotion_Switch_btn(isOpen) {
      this.promotion_form.isBeginImage = isOpen
    },
    // async promotion_Switch_btn_openTwo(isOpen) {
      // this.promotion_form.isBannerShow = isOpen
    // },
    // promotion_Switch_btn_openThree(isOpen){
    //   this.promotion_form.openThree = isOpen
    // },
    //修改直播会议上移下移
    async getListUpDown(id, action) {
      const response = await LiveList_upOrDown(id, action)
      if(response.code === 0){
        this.$message.success("修改成功！")
      //  刷新列表
        await this.getLiveList(this.page_no,this.page_size,this.titleData,this.radioValue,this.year,this.domainCode)
      }else {
        this.$message.warning("失败"+response.message)
      }
    },
    upBtn(list){
      this.getListUpDown(list.id, 'up')
    },
    DownBtn(list){
      this.getListUpDown(list.id, 'down')
    },
    Subordinate_handleChange(){

    },
    live_begin_time_change_add(date){
      this.form.sortTime = moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    live_begin_time_change_rest(date){
      this.editForm.sortTime = moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  }
}
</script>

<style scoped lang="scss">

.liveMeet{
  .liveMeet-top{
    width: 100%;
    height: 85px;
    border-bottom: 15px solid #f0f2f5;
    display: flex;
    justify-content: space-between;
    .liveMeet-top-input{
      display: flex;
      justify-content: center;
      width: 25%;
      height: 50px;
      line-height: 30px;
      padding-top: 20px;
      .input{
        width: 65%;
        margin-left: 10px;
      }
    }
  }
  .liveMeet-table{
    width: 100%;
    min-height: 900px;
    background-color: #f0f2f5;
    .table-clo{
      height: 300px;
      background-color: #fff;
    }
    .liveMeet-table-div{
      width: 100%;
      height: auto;
      margin: auto;
      //display: flex;
      //justify-content: space-around;
      .liveMeet-table-div-div-one{
        width: 23%;
        float: left;
        height: 270px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f2f5;
        border: 1px dashed #a2a1a1;
        color: #8c8a8a;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 1%;
        .liveMeet-table-div-div-one-font{
        }
      }
      .liveMeet-table-div-div{
        width: 23%;
        min-height: 270px;
        float: left;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 1%;
        background-color: #fff;
        box-shadow: 0px 0px 5px 3px #ececec;
        position: relative;
        .box{
          width: 100%;
          height: 0px;
          position: relative;
          padding-bottom: 56.25%;
          background-color: #f8f7f7;
          .upDown{
            cursor: pointer;
            position: absolute;
            width: 100%;
            height:100%;
            background-color: rgba(0,0,0,0);
            font-size: 16px;
            //动画
            transition: background-color 0.5s;
            .font{
              display: none;
              color: #000;
              font-size: 20px;
              cursor: pointer;
            }
          }

          .upDown:hover{
            background-color: rgba(0,0,0,0.5);
            .font{
              display: block;
              font-size:30px;
              color: #f1f1f1;
              cursor: pointer;
            }
            .font:hover{
              color: #d2d1d1;
            }
          }
          img{
            width: 100%;
            height: 100%;
            position: absolute;
          }
        }
        .content{
          width: 100%;
          .content-title{
            width: 95%;
            height: 30px;
            margin: auto;
            min-height: 50px;
            font-size: 16px;
            color: black ;
            font-weight: bold;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /*这里可以设置文本显示的行数*/
            overflow: hidden;
          }
          .content-time{
            width: 95%;
            height: 50px;
            margin: auto;
            display: flex;
            justify-content: space-between;
            color: #8c8a8a;
            font-size: 12px;
            margin-bottom: 5px;
            //position: absolute;
            //bottom: 5px;
            left: 10px;
          }
          .content-btn{
            display: flex;
            justify-content: space-around;
            color: #868686;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            background-color: #f5f5f5;
            border-top: 1px solid #e8e8e8;
            position: absolute;
            bottom: 0px;
            width: 100%;
            .btn-one{
              color: #868686;
              cursor: pointer;
            }
            .btn-one:hover{
              color: #1890ff;
            }

          }
        }
      }
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  //width: 320px;
  //height: 86.5px;
  width: 220px;
  height: 70px;
  margin-left: 20px;
  text-align: center;
}
.upload_deyails{
  border: #DDDDDD 2px dashed;
  width: 220px;
  height: 130px;
  margin-left: 20px;
  text-align: center;
}
.upload_poster{
  border: #DDDDDD 2px dashed;
  width: 110px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
.aleart{
  margin-top: 2px;
  padding-left: 10px;
  height: 32px;
  width: 150px;
  line-height: 32px;
  background-color: #eafbff;
  border-radius: 5px;
  border: 1px solid #3a9af3;
}
</style>